<style lang="less" scoped>
#commodity-detail {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .tab-top-line {
    width: 100%;
    height: 2px;
    background-color: #F7F8FA;
    margin-top: 12px;
  }
  .commodity-info {
    margin: 30px 40px 60px 40px;
    ::v-deep .ant-form-item {
      margin-bottom: 15px ;
      height: 46px;
    }
    ::v-deep .ant-form-item-label {
      width: 100px ;
    }
    ::v-deep .ant-form-item-label,
    label {
      color: #666666;
      font-size: 16px ;
    }
    ::v-deep .ant-form-item-label > label:after {
      content: "" ;
    }
    ::v-deep .ant-form-item-control-wrapper {
      display: inline-block ;
      margin-left: 10px ;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-calendar-picker,
    ::v-deep .ant-calendar-picker-input {
      height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-cascader-picker,
    ::v-deep .ant-cascader-input {
      height: 46px;
      width: 350px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-children, input {
      height: 46px;
      line-height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      height: 46px;
      line-height: 46px;
    }
    .flex-vw {
      display: flex;
      ::v-deep .ant-form-item-control-wrapper,
      ::v-deep .ant-form-item-control,
      ::v-deep .ant-form-item-children,
      ::v-deep .ant-cascader-picker,
      ::v-deep .ant-cascader-input {
        width: 200px;
      } 
      .cus-left {
        margin-left: 80px;
      }
    }
    .tips-div {
      margin-left: 33px;
      margin-top: 30px;
      display: flex;
      .tips-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
      }
      .tips-list {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        display: inline-block;
        margin-left: 15px;
      }
    }
  }
  .save-button {
    width: 480px;
    height: 60px;
    margin-top: 50px;
    margin-left: 30px;
    border-radius: 4px;
    background-color: #447df5;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="commodity-detail" id="commodity-detail">
    <div class="tab-top">
      <table-title />
    </div>
    <div class="tab-top-line"></div>
    <div class="commodity-info">
      <template>
        <a-form-model ref="infoForm" :model="form">
          <a-form-model-item label="商品名称" prop="commodity_name" ref="commodity_name">
            <a-input v-model="form.commodity_name" placeholder="请输入商品名称"/>
          </a-form-model-item>
          <a-form-model-item label="消耗金币数量" prop="commodity_price" ref="commodity_price">
            <a-input-number v-model="form.commodity_price" size="large" :min="0" :default-value="0" style="width:150px"/>
          </a-form-model-item>
          <a-form-model-item label="商品描述" prop="commodity_desc" ref="commodity_desc">
            <a-input v-model="form.commodity_desc" placeholder="请输入商品描述"/>
          </a-form-model-item>
        </a-form-model>
      </template>
      <a-button type="save" class="save-button" @click="onSubmit" :loading="loading">保存</a-button>
    </div>
  </div>
</template>

<script>
import { createCommodity, fetchCommodityInfo, updateCommodity } from "@/api/commodity"
export default {
  name: "CommodityDetail",
  components: {
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
  },
  data() {
    return {
      loading: false,
      commodityId: undefined,
      form: {
        commodity_name: undefined,
        commodity_price: 0,
        commodity_desc: undefined,
      },
    };
  },
  created() {
    this.commodityId = this.$route.query.commodityId;
    this.initData();
  },
  methods: {
    initData() {
      if (this.commodityId != undefined) {
        this.getCommodityDetail()
      }
    },
    /// 获取用户信息
    async getCommodityDetail() {
      try {
        let res = await fetchCommodityInfo({
          commodity_id: this.commodityId
        });
        this.form.commodity_name = res.commodity_name;
        this.form.commodity_price = res.commodity_price;
        this.form.commodity_desc = res.commodity_desc;
      } catch (error) {
        console.log(error)
      }
    },
    onSubmit() {
      if (!this.form.commodity_name) {
        this.$message.error("请输入商品名称");
        return
      }
      if (this.form.commodity_price <= 0) {
        this.$message.error("请输入商品消耗金币数量");
        return
      }
      if (!this.form.commodity_desc) {
        this.$message.error("请输入商品描述");
        return
      }
      this.createCommodityData();
    },
    async createCommodityData() {
      this.loading = true;
      try {
        let params = {
          commodity_name: this.form.commodity_name,
          commodity_desc: this.form.commodity_desc,
          commodity_price: this.form.commodity_price,
        };
        if (this.commodityId == undefined) {
          params["commodity_status"] = 1;
          await createCommodity(params);
          this.$message.success("商品添加成功");
        }else {
          params["commodity_id"] = this.commodityId;
          await updateCommodity(params);
          this.$message.success("商品更新成功");
        }
        this.loading = false;
        const that = this;
        setTimeout(() => {
          if (that.commodityId == undefined) {
            that.$router.push({ name: "commodity"});
          }else {
            that.$router.go(-1);
          }
        }, 500);
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
  }
};
</script>