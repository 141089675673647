import https from '../utils/https'

// 商品管理接口
export function fetchCommoditylist(params) {
  return https.fetchPost('/api/backstage/commodity/list', params)
}

// 获取商品信息接口
export function fetchCommodityInfo(params) {
  return https.fetchGet('/api/backstage/commodity/info', params)
}

// 创建商品接口
export function createCommodity(params) {
  return https.fetchPost('/api/backstage/commodity/create', params)
}

// 更新商品属性接口
export function updateCommodity(params) {
  return https.fetchPost('/api/backstage/commodity/update', params)
}